import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import './index.sass'

export function Popup({ visible, onClose, children }) {
  if (!visible) return null

  return (
    <div className="popup">
      <div onClick={onClose} className="popup__close-img-container">
        <StaticImage
          src="../../../assets/common__close-button.png"
          alt="close"
          placeholder="blurred"
          className="popup__close-img"
        />
      </div>
      {children}
    </div>
  )
}