import React from 'react'
import { useTranslation } from 'react-i18next'
import { useStaticQuery, graphql } from 'gatsby'
import { sprintf } from 'sprintf-js'

import { getResolvedLanguage } from '../../services/get-language'
import { MarketLanguageSelector } from '../common/market-selector/market-selector'
import { GetStartedContent } from '../common/get-started/components/content'
import './index.sass'

export const GetStartedPage = ({ country }) => {
  const { t } = useTranslation()
  const language = getResolvedLanguage()

  const { allMdx } = useStaticQuery(graphql`
    query {
      allMdx(filter: {frontmatter: {slug: {eq: "getstarted"}}}) {
        nodes {
          frontmatter {
            slug
            country
            get_started_page {
              buttons_title
              button_first_title
              button_first_description
              button_first_link_title
              button_first_link_address
              button_second_title
              button_second_description
              button_second_link_title
              button_second_link_address
              button_second_bottom_main_text
              button_second_bottom_link_text
              button_second_bottom_link
              popup_title
              qr_title
              qr_description
              sign_in_title
              sign_in_description
              get_started
              store_links {
               title
               link
               store_image {
                 childImageSharp {
                   gatsbyImageData
                 }
               }
              }
            }
          }
        }
      }
    }
  `)

  const copies = allMdx.nodes.find(node => node.frontmatter.country === country).frontmatter.get_started_page

  return (
    <div className="get-started-page">
      <div className="get-started-page__market-selector">
        <MarketLanguageSelector textStyle={{ color: 'white' }} currentCountryCode={country} />
      </div>
      <GetStartedContent
        buttonsTitle={t(copies.buttons_title)}
        firstLinkAddress={copies.button_first_link_address}
        firstButtonTitle={t(copies.button_first_title)}
        firstButtonDescription={t(copies.button_first_description)}
        secondLinkAddress={sprintf(copies.button_second_link_address, language)}
        secondButtonTitle={t(copies.button_second_title)}
        secondButtonDescription={t(copies.button_second_description)}
        secondButtonBottomLink={sprintf(copies.button_second_bottom_link, language)}
        storeLinks={copies.store_links}
        popupTitle={t(copies.popup_title)}
        qrTitle={t(copies.qr_title)}
        qrDescription={t(copies.qr_description)}
        signInTitle={t(copies.sign_in_title)}
        signInDescription={t(copies.sign_in_description)}
        getStarted={t(copies.get_started)}
      />
    </div>
  )
}
