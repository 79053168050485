import React from 'react'
import { navigate } from 'gatsby'
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image'
import { useTranslation } from 'react-i18next'

import { AppContext } from '../../../layouts'
import { windowGlobal } from '../../../utils/window-global'
import { Header as PageHeader } from '../../common/header'
import { Link } from '../../common/link'
import './index.sass'

export const WorkHeaderBase = ({ linkColor, buttonColor, headerSection, country }) => {
  const { t } = useTranslation()
  const pageContext = React.useContext(AppContext);
  const [selectedAddress, setSelectedAddress] = React.useState(null);

  const onStoreButtonClick = () => {
    windowGlobal.gtag?.('event', 'conversion', { 'send_to': 'AW-10960749734/7WBSCLX4ntgYELOj1-wC' })
  }

  const { background_image, background_image_mobile } = headerSection
  const isMobile = windowGlobal.innerWidth < 1200
  const bgImg = isMobile ? background_image_mobile.childImageSharp.original.src : background_image.childImageSharp.original.src

  const onFindJobsPress = () => {
    if (selectedAddress === null) {
      navigate(`/${pageContext.country}/${pageContext.language}/jobs`)
    } else {
      navigate(`/${pageContext.country}/${pageContext.language}/jobs/${selectedAddress.toLowerCase()}`)
    }
  }

  return (
    <div
      className="work container"
      style={{
        backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 0.8), transparent), url(${bgImg})`
      }}
    >
      <PageHeader
        withWhiteLogo
        linkColor={linkColor}
        buttonColor={buttonColor}
        country={country}
        showGetStarted={false}
        showDownloadSpApp
      />
      <div className="content">
        <div>
          <div className="title-container">
            <span className="title">{t(headerSection.title)}</span>
          </div>
          <div className="search-container">
            <div className="select-container">
              <StaticImage
                src="../../../assets/app/location-grey.png"
                className="location-img"
              />
              <select
                className="select-address"
                value={selectedAddress}
                onChange={ev => {
                  if (ev.target.value === "-1") {
                    setSelectedAddress(null);
                  } else {
                    setSelectedAddress(ev.target.value);
                  }
                }}
              >
                <option value="-1">Choose your city</option>
                {pageContext.marketAddresses.map(address => (
                  <option key={address} value={address}>{address}</option>
                ))}
              </select>
              <StaticImage
                src="../../../assets/app/arrow-down-blue.png"
                className="arrow-img"
              />
            </div>
            <button className="search-btn" onClick={onFindJobsPress}>
              Find jobs
            </button>
          </div>
        </div>
        <div className="bottom-container">
          <div className="download-label">Download the app to get started</div>
          <div className="stores-container">
            {headerSection.store_list.map(storeLinkItem => (
              <Link
                to={storeLinkItem.link}
                key={storeLinkItem.title}
                className="store-link"
                onClick={onStoreButtonClick}
              >
                <GatsbyImage
                  image={getImage(storeLinkItem.store_image)}
                  alt={storeLinkItem.title}
                  className="store-img"
                />
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
