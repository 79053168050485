import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { useTranslation } from 'react-i18next'
import { useStaticQuery, graphql } from 'gatsby'

import { windowGlobal } from '../../../utils/window-global'
import { WorkInUaeNav } from './nav/nav'
import { Link } from '../../common/link'
import './index.sass'

export const WorkInHeader = ({ linkColor, buttonColor, country }) => {
  const { t } = useTranslation()

  const { allMdx } = useStaticQuery(graphql`
    query {
      allMdx(filter: {frontmatter: {slug: {regex: "/^workin/"}}}) {
        nodes {
          frontmatter {
            country
            slug
            work_header_section {
              title
              subtitle
              store_list {
                link
                title
                store_image {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              partners_title
              partners {
                partner_image {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
                alt
              }
              background_image {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
              background_image_mobile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const onStoreButtonClick = () => {
    windowGlobal.gtag?.('event', 'conversion', { 'send_to': 'AW-10960749734/7WBSCLX4ntgYELOj1-wC' })
  }

  const nodeCountry = allMdx.nodes.find(node => node.frontmatter.country === country)
  const headerSection = nodeCountry.frontmatter.work_header_section
  const { background_image, background_image_mobile } = headerSection
  const isMobile = windowGlobal.innerWidth < 1200
  const bgImg = isMobile ? background_image_mobile.childImageSharp.original.src : background_image.childImageSharp.original.src

  return (
    <div
      className="work-in-uae container"
      style={{
        backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 0.8), transparent), url(${bgImg})`
      }}
    >
      <WorkInUaeNav withWhiteLogo country={country} linkColor={linkColor} buttonColor={buttonColor} />
      <div className="content">
        <div className="title-container">
          <span className="title">{t(headerSection.title)}</span>
        </div>
        <div className="subtitle-container">
          <span className="subtitle">{t(headerSection.subtitle)}</span>
        </div>
        <div className="stores-container">
          {headerSection.store_list.map(storeLinkItem => (
            <Link
              to={storeLinkItem.link}
              key={storeLinkItem.title}
              className="store-link"
              onClick={onStoreButtonClick}
            >
              <GatsbyImage
                image={getImage(storeLinkItem.store_image)}
                alt={storeLinkItem.title}
                className="store-img"
              />
            </Link>
          ))}
        </div>
        <div className="partners-container">
          <span className="partners-title">{t(headerSection.partners_title)}</span>
          <ul className="list">
            {headerSection.partners.map(partnerItem => (
              <li className="item" key={partnerItem.alt}>
                <GatsbyImage
                  image={getImage(partnerItem.partner_image)}
                  alt={partnerItem.alt}
                  className="image"
                />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}
