import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { useTranslation } from 'react-i18next'

import './index.sass'

export const CommonStatistics = ({ slug, country }) => {
  const { t } = useTranslation()

  const { allMdx } = useStaticQuery(graphql`
    query {
      allMdx {
        nodes {
          frontmatter {
            slug
            country
            common_statistics_section {
              title
              subtitle
              background_image {
                childImageSharp {
                  gatsbyImageData
                }
              }
              list {
                title
                subtitle
                stats_image {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const statsSection = allMdx.nodes.find(node => node.frontmatter.slug === slug && node.frontmatter.country === country).frontmatter.common_statistics_section

  return (
    <div className="common-statistics container">
      <div className="first">
        <span className="title">{t(statsSection.title)}</span>
        <span className="subtitle">{t(statsSection.subtitle)}</span>
        <GatsbyImage
          image={getImage(statsSection.background_image)}
          alt=""
          className="bg-image"
        />
      </div>
      <div className="second">
        {statsSection.list.map(statItem => (
          <div key={statItem.title} className="item">
            <GatsbyImage
              image={getImage(statItem.stats_image)}
              alt=""
              className="item-image"
            />
            <span className="title">{t(statItem.title)}</span>
            <span className="subtitle">{t(statItem.subtitle)}</span>
          </div>
        ))}
      </div>
      <GatsbyImage
        image={getImage(statsSection.background_image)}
        alt=""
        className="image"
      />
    </div>
  )
}
