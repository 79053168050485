import React from 'react'

import { GetStartedContent } from './content'
import { Popup } from '../../popup'
import './popup.sass'

export function GetStartedPopup({
  visible,
  onClose,
  buttonsTitle,
  firstLinkAddress,
  firstButtonTitle,
  firstButtonDescription,
  secondButtonTitle,
  secondButtonDescription,
  secondButtonBottomLink,
  storeLinks,
  popupTitle,
  popupSubtitle,
  qrTitle,
  qrDescription,
  signInTitle,
  signInDescription,
  getStarted,
  secondLinkAddress
}) {
  return (
    <Popup visible={visible} onClose={onClose}>
      <GetStartedContent
        buttonsTitle={buttonsTitle}
        firstLinkAddress={firstLinkAddress}
        firstButtonTitle={firstButtonTitle}
        firstButtonDescription={firstButtonDescription}
        secondButtonTitle={secondButtonTitle}
        secondButtonDescription={secondButtonDescription}
        secondButtonBottomLink={secondButtonBottomLink}
        storeLinks={storeLinks}
        popupTitle={popupTitle}
        popupSubtitle={popupSubtitle}
        secondLinkAddress={secondLinkAddress}
        qrTitle={qrTitle}
        qrDescription={qrDescription}
        signInTitle={signInTitle}
        signInDescription={signInDescription}
        getStarted={getStarted}
      />
    </Popup>
  )
}