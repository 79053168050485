import React, { useContext } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { useTranslation } from 'react-i18next'
import { useStaticQuery, graphql } from 'gatsby'
import './index.sass'
import { AppContext } from '../../../layouts'

export const HireStartHire = ({ country }) => {
  const { setGetStartedPopupVisible } = useContext(AppContext)
  const { t } = useTranslation()

  const { allMdx } = useStaticQuery(graphql`
    query {
      allMdx(filter: {frontmatter: {slug: {eq: "hire"}}}) {
        nodes {
          frontmatter {
            country
            hire_start_hire_section {
              title
              subtitle
              button_title
              background_image {
                childImageSharp {
                  gatsbyImageData
                  original {
                    src
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const startHireSection = allMdx.nodes.find(node => node.frontmatter.country === country).frontmatter.hire_start_hire_section
  const bgImage = startHireSection.background_image.childImageSharp.original.src

  return (
    <div className="start-hire container">
      <div
        className="container"
        style={{ backgroundImage: `url(${bgImage})` }}
      >
        <span className="title">{t(startHireSection.title)}</span>
        <span className="subtitle">{t(startHireSection.subtitle)}</span>
        <div onClick={() => { setGetStartedPopupVisible(true) }} className="button">
          <span className="label">{t(startHireSection.button_title)}</span>
          <StaticImage
            src="../../../assets/common__arrow-right-black.png"
            alt=""
            className="image"
          />
        </div>
      </div>
    </div>
  )
}
