import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { StaticImage } from 'gatsby-plugin-image'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { useStaticQuery, graphql } from 'gatsby'
import { Link } from '../../common/link'
import './index.sass'
import { AppContext } from '../../../layouts'

export const Find = ({ country }) => {
  const { setGetStartedPopupVisible } = useContext(AppContext)
  const { t } = useTranslation()

  const { allMdx } = useStaticQuery(graphql`
    query {
      allMdx(filter: {frontmatter: {slug: {eq: "index"}}}) {
        nodes {
          frontmatter {
            slug
            country
            home_find_section {
              find_workers_title
              find_workers_subtitle
              find_workers_description
              find_workers_button_title
              find_work_title
              find_work_subtitle
              find_work_button_title
              find_work_button_link
              find_workers_image {
                childImageSharp {
                  gatsbyImageData
                  original {
                    src
                  }
                }
              }
              find_work_desktop_image {
                childImageSharp {
                  gatsbyImageData
                  original {
                    src
                  }
                }
              }
              find_work_mobile_image {
                childImageSharp {
                  gatsbyImageData
                }
              }
              find_work_benefits {
                title
                benefit_image {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              find_workers_benefits {
                title
              }
            }
          }
        }
      }
    }
  `)
  const findSection = allMdx.nodes.find(node => node.frontmatter.country === country).frontmatter.home_find_section
  const findWorkersImgResource = findSection.find_workers_image
  const findWorkMobileImgResource = findSection.find_work_mobile_image
  const findWorkDesktopImgResource = findSection.find_work_desktop_image

  const findWorkersImgSrc = findWorkersImgResource.childImageSharp.original.src
  const findWorkImgSrc = findWorkDesktopImgResource.childImageSharp.original.src

  return (
    <div
      className="home-find container"
      style={{ backgroundImage: `url(${findWorkersImgSrc}), url(${findWorkImgSrc})` }}
    >
      <div className="find-workers">
        <div className="content">
          <span className="title">{t(findSection.find_workers_title)}</span>
          <span className="subtitle">{t(findSection.find_workers_subtitle)}</span>
          <span className="description">{t(findSection.find_workers_description)}</span>
          <ul className="list">
            {findSection.find_workers_benefits.map(item => (
              <li key={item.title} className="item">
                <div className="checkmark-container">
                  <StaticImage
                    src="../../../assets/common__checkmark.png"
                    alt=""
                    placeholder="blurred"
                    className="checkmark"
                  />
                </div>
                <span className="label">{t(item.title)}</span>
              </li>
            ))}
          </ul>
          <div className="hire-button" onClick={() => { setGetStartedPopupVisible(true) }}>
            <span className="label">{t(findSection.find_workers_button_title)}</span>
            <StaticImage
              src="../../../assets/common__arrow-right.png"
              alt=""
              placeholder="blurred"
              className="arrow-img"
            />
          </div>
        </div>
        <GatsbyImage
          image={getImage(findWorkersImgResource)}
          alt=""
          className="section-img"
        />
      </div>
      <div className="find-work">
        <div className="content">
          <span className="title">{t(findSection.find_work_title)}</span>
          <span className="subtitle">{t(findSection.find_work_subtitle)}</span>
          <ul className="benefitList">
            {findSection.find_work_benefits.map(item => (
              <div key={item.title} className="item">
                <GatsbyImage
                  image={getImage(item.benefit_image)}
                  alt=""
                  className="item-img"
                />
                <span className="label">{t(item.title)}</span>
              </div>
            ))}
          </ul>
          <div className="button-container">
            <Link to={findSection.find_work_button_link} className="work-button">
              <span className="label">{t(findSection.find_work_button_title)}</span>
              <StaticImage
                src="../../../assets/common__arrow-right.png"
                alt=""
                placeholder="blurred"
                className="arrow-img"
              />
            </Link>
          </div>
        </div>
        <GatsbyImage
          image={getImage(findWorkMobileImgResource)}
          alt=""
          className="find-work-img"
        />
      </div>
    </div>
  )
}
