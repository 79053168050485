import React from 'react'
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image'
import { useTranslation } from 'react-i18next'
import { useStaticQuery, graphql } from 'gatsby'

import { Link } from '../../common/link'
import './index.sass'

export const WorkJobTypes = ({ country }) => {
  const { t } = useTranslation()

  const { allMdx } = useStaticQuery(graphql`
    query {
      allMdx(filter: {frontmatter: {slug: {eq: "work"}}}) {
        nodes {
          frontmatter {
            country
            work_job_types_section {
              title
              list {
                title
                type
                primary_color
                second_color
                price
                location
                duration
                company_name
                company_image {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
                post_time
              }
              hints {
                hint_image {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
                button_link
                button_title
                description
                author_name
                author_image {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
                post_time
              }
              total {
                amount
                description
                button_title
              }
            }
          }
        }
      }
    }
  `)

  const jobTypesSection = allMdx.nodes.find(node => node.frontmatter.country === country).frontmatter.work_job_types_section

  if (!jobTypesSection) return null

  const firstThreeItems = jobTypesSection.list.slice(0, 3)
  const restOfItems = jobTypesSection.list.slice(3)

  const renderItem = item => (
    <Link to="jobs" className="job-types-item">
      <div className="job-container">
        <div className="job-top">
          <div className="job-header">
            <div
              className="job-type-item-header-container"
              style={{ backgroundColor: item.second_color }}
            >
              <div className="dot" style={{ backgroundColor: item.primary_color }} />
              <span className="label">{t(item.type)}</span>
            </div>
            <span className="price-label">{t(item.price)}</span>
          </div>
          <span className="job-title">{t(item.title)}</span>
          <div className="job-info">
            <div className="job-info-item">
              <StaticImage
                src="./assets/location.png"
                className="job-info-item-image"
                alt=""
              />
              <span className="job-info-item-label">{t(item.location)}</span>
            </div>
            <div className="job-info-item">
              <StaticImage
                src="./assets/clock.png"
                className="job-info-item-image"
                alt=""
              />
              <span className="job-info-item-label">{t(item.duration)}</span>
            </div>
          </div>
        </div>
        <div className="job-footer">
          <div className="job-footer-left">
            <GatsbyImage
              image={getImage(item.company_image)}
              className="job-photo"
              alt=""
            />
            <span className="job-name">{t(item.company_name)}</span>
          </div>
          <span className="job-footer-time">{t(item.post_time)}</span>
        </div>
      </div>
    </Link>
  )

  const renderTotal = () => (
    <Link to="jobs" className="job-types-item">
      <div className="jobs-total-container">
        <span className="jobs-total-title">{t(jobTypesSection.total.amount)}</span>
        <span className="jobs-total-subtitle">{t(jobTypesSection.total.description)}</span>
        <div
          className="jobs-total-button"
          title={t(jobTypesSection.total.button_title)}
        >
          <span className="label">{t(jobTypesSection.total.button_title)}</span>
        </div>
      </div>
    </Link>
  )

  return (
    <div className="work-job-types container">
      <span className="work-job-types-title">{t(jobTypesSection.title)}</span>
      <div className="work-job-types-content">
        <div className="job-types-line">
          {firstThreeItems.map(renderItem)}
          {renderTotal()}
        </div>
        <div className="second-row">
          <div className="job-types-hint-container">
            <div className="hint-image-wrapper">
              <GatsbyImage
                image={getImage(jobTypesSection.hints.hint_image)}
                className="hint-image"
                alt=""
              />
              <Link to={jobTypesSection.hints.button_link} className="hint-button">
                <span className="label">{t(jobTypesSection.hints.button_title)}</span>
                <StaticImage
                  src="./assets/point-arrow.png"
                  className="image"
                  alt=""
                />
              </Link>
            </div>
            <span className="hint-description">
              {t(jobTypesSection.hints.description)}
            </span>
            <div className="hint-footer">
              <GatsbyImage
                image={getImage(jobTypesSection.hints.author_image)}
                className="author-image"
                alt=""
              />
              <span className="author-name">
                {jobTypesSection.hints.author_name}
              </span>
              <span className="author-post-time">
                {t(jobTypesSection.hints.post_time)}
              </span>
            </div>
          </div>
          <div className="job-types-grid">
            {restOfItems.map(renderItem)}
            {renderTotal()}
          </div>
        </div>
      </div>
    </div>
  )
}
