import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { useTranslation } from 'react-i18next'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import { Link } from '../../../components/common/link'
import './index.sass'

export const Footer = ({ country }) => {
  const { t } = useTranslation()

  const { allMdx } = useStaticQuery(graphql`
    query {
      allMdx(filter: {frontmatter: {slug: {eq: "footer"}}}) {
        nodes {
          frontmatter {
            slug
            country
            common_footer_section {
              company_definition
              copyright
              navigation {
                title
                links {
                  title
                  link
                }
              }
              social_links {
                title
                link
                social_image {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              store_links {
                title
                link
                store_image {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const footerSection = allMdx.nodes.find(node => node.frontmatter.country === country).frontmatter.common_footer_section

  return (
    <div className="footer container">
      <div className="content">
        <div className="navigation">
          {footerSection.navigation.map(item => (
            <div className="column" key={item.title}>
              <span className="title">{t(item.title)}</span>
              <ul className="links">
                {item.links.map(linkItem => (
                  <li key={linkItem.title}>
                    {linkItem.link ? (
                      <Link
                        className="item"
                        to={linkItem.link}
                        activeStyle={{ color: '#7767E4', fontWeight: 700 }}
                      >
                        {t(linkItem.title)}
                      </Link>
                    ) : (
                      <span className="item">
                        {t(linkItem.title)}
                      </span>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
        <div className="description-container">
          <StaticImage
            src="../../../assets/common__logo.png"
            alt="Ogram"
            placeholder="blurred"
            className="logo-img"
          />
          <span className="title">{t(footerSection.company_definition)}</span>
        </div>
        <div className="social-container">
          <ul className="links">
            {footerSection.social_links.map(socialLinkItem => (
              <Link
                to={socialLinkItem.link}
                key={socialLinkItem.title}
                className="item"
              >
                <GatsbyImage
                  image={getImage(socialLinkItem.social_image)}
                  alt={socialLinkItem.title}
                  className="link-img"
                />
              </Link>
            ))}
          </ul>
        </div>
        <div className="store-container">
          {footerSection.store_links.map(storeLinkItem => (
            <Link
              to={storeLinkItem.link}
              key={storeLinkItem.title}
              className="store-link"
            >
              <GatsbyImage
                image={getImage(storeLinkItem.store_image)}
                alt={storeLinkItem.title}
                className="store-img"
              />
            </Link>
          ))}
        </div>
      </div>
      <div className="copyright-container">
        <StaticImage
          src="../../../assets/common__heart.webp"
          alt=""
          placeholder="blurred"
          className="heart-img"
        />
        <span className="label">{t(footerSection.copyright)}</span>
      </div>
    </div>
  )
}
