import React from 'react'
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image'
import { useTranslation } from 'react-i18next'
import { useStaticQuery, graphql } from 'gatsby'

import './index.sass'

export const WorkClients = ({ country }) => {
  const { t } = useTranslation()

  const { allMdx } = useStaticQuery(graphql`
    query {
      allMdx(filter: {frontmatter: {slug: {eq: "work"}}}) {
        nodes {
          frontmatter {
            country
            work_clients_section {
              title
              list {
                title
                item_image {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const clientsSection = allMdx.nodes.find(node => node.frontmatter.country === country).frontmatter.work_clients_section
  return (
    <div className="clients">
      <div className="title container">{t(clientsSection.title)}</div>
      <ul className="list container">
        {clientsSection.list.map(item => {
          return (
            <li key={item.title} className="list-item">
              <GatsbyImage
                image={getImage(item.item_image)}
                className="img"
                alt={item.title}
              />
            </li>
          )
        })}
      </ul>
    </div>
  )
}