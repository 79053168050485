import React, { useContext } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { useTranslation } from 'react-i18next'
import { Link } from '../../common/link'
import { BurgerButton } from './components/burger-button'
import './index.sass'
import { AppContext } from '../../../layouts'
import { MarketLanguageSelector } from "../market-selector/market-selector";
import { DownloadSpAppPopup } from '../download-sp-app-popup'

export const Header = ({
  linkColor,
  buttonColor,
  country,
  withWhiteLogo,
  activeLinkColor,
  showGetStarted = true,
  showDownloadSpApp = false,
}) => {
  const [isDownloadPopupVisible, setDownloadPopupVisible] = React.useState(false)
  const { setGetStartedPopupVisible } = useContext(AppContext)

  const { t } = useTranslation()

  const { allMdx } = useStaticQuery(graphql`
    query {
      allMdx(filter: {frontmatter: {slug: {eq: "navigation-bar"}}}) {
        nodes {
          frontmatter {
            slug
            country
            common_navigation_section {
              links {
                title
                link
                nested_links {
                  title
                  link
                }
              }
              start_button {
                title
              }
              download_button {
                title
              }
              footer_label
              get_started_button_title
            }
          }
        }
      }
    }
  `)

  const headerSection = allMdx.nodes.find(node => node.frontmatter.country === country).frontmatter.common_navigation_section

  return (
    <div className="header">
      <Link to="" className="logo-container">
        {withWhiteLogo ? (
          <StaticImage
            src="../../../assets/common__logo-white.png"
            alt="Ogram"
            placeholder="blurred"
            className="logo"
          />
        ) : (
          <StaticImage
            src="../../../assets/common__logo.png"
            alt="Ogram"
            placeholder="blurred"
            className="logo"
          />
        )}
      </Link>
      <div className="burger-button">
        <BurgerButton
          isLightTheme={withWhiteLogo}
          links={headerSection.links}
          getStartedButtonTitle={headerSection.get_started_button_title}
          footerLabel={headerSection.footer_label}
          country={country}
        />
      </div>
      <nav className="navigation">
        {headerSection.links.map(linkItem => {
          if (linkItem.nested_links.length > 0) {
            return (
              <div key={linkItem.title} className="link nested">
                <span style={{ color: linkColor }}>
                  {t(linkItem.title)}
                </span>
                <svg
                  width="9"
                  height="7"
                  viewBox="0 0 9 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="arrow"
                >
                  <path d="M4.03516 6.40234C4.28125 6.64844 4.69141 6.64844 4.9375 6.40234L8.65625 2.68359C8.92969 2.41016 8.92969 2 8.65625 1.75391L8.05469 1.125C7.78125 0.878906 7.37109 0.878906 7.125 1.125L4.47266 3.77734L1.84766 1.125C1.60156 0.878906 1.19141 0.878906 0.917969 1.125L0.316406 1.75391C0.0429688 2 0.0429688 2.41016 0.316406 2.68359L4.03516 6.40234Z" fill={linkColor} />
                </svg>
                <div className="dropdown">
                  {linkItem.nested_links.map(nestedLink => (
                    <Link
                      className="dropdown-link"
                      to={nestedLink.link}
                      key={nestedLink.title}
                    >
                      {t(nestedLink.title)}
                    </Link>
                  ))}
                </div>
              </div>
            )
          }
          return (
            <Link
              to={linkItem.link}
              key={linkItem.title}
              activeStyle={{ color: activeLinkColor ? activeLinkColor : '#7767E4' }}
              style={{ color: linkColor }}
              className="link"
            >
              {t(linkItem.title)}
            </Link>
          )
        })}
        <MarketLanguageSelector textStyle={{ color: linkColor }} currentCountryCode={country} />
        {showGetStarted && (
          <div
            onClick={() => { setGetStartedPopupVisible(true) }}
            className="button"
            style={{ borderColor: buttonColor }}
          >
            <span style={{ color: buttonColor }} className="label">
              {t(headerSection.start_button.title)}
            </span>
          </div>
        )}
        {showDownloadSpApp && (
          <div
            onClick={() => { setDownloadPopupVisible(true) }}
            className="button"
            style={{ borderColor: buttonColor }}
          >
            <span style={{ color: buttonColor }} className="label">
              {t(headerSection.download_button.title)}
            </span>
          </div>
        )}
      </nav>
      <DownloadSpAppPopup isVisible={isDownloadPopupVisible} setVisible={setDownloadPopupVisible} />
    </div>
  )
}
