import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { useTranslation } from 'react-i18next'
import { useStaticQuery, graphql } from 'gatsby'

import './index.sass'

export const Partners = ({ country }) => {
  const { t } = useTranslation()

  const { allMdx } = useStaticQuery(graphql`
    query {
      allMdx(filter: {frontmatter: {slug: {eq: "index"}}}) {
        nodes {
          frontmatter {
            slug
            country
            home_partners_section {
              title
              description
              image_list {
                partner_image {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
                alt
              }
            }
          }
        }
      }
    }
  `)

  const partnersSection = allMdx.nodes.find(node => node.frontmatter.country === country).frontmatter.home_partners_section

  return (
    <div className="home-partners container">
      <div className="text-container">
        <span className="title">{t(partnersSection.title)}</span>
        <span className="description">{t(partnersSection.description)}</span>
      </div>
      <div className="images">
        {partnersSection.image_list.map((item, index) => (
          <GatsbyImage
            key={index}
            image={getImage(item.partner_image)}
            alt={item.alt}
            imgStyle={{ objectFit: 'contain' }}
            className="image"
            backgroundColor="transparent"
          />
        ))}
      </div>
    </div>
  )
}
