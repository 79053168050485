import React from 'react'
import { useTranslation } from 'react-i18next'
import { useStaticQuery, graphql } from 'gatsby'
import { sprintf } from 'sprintf-js'

import { getResolvedLanguage } from '../../../services/get-language'
import { GetStartedPopup } from './components/popup'

export const GetStarted = ({ visible, onClose, country }) => {
  const { t } = useTranslation()
  const language = getResolvedLanguage()

  const { allMdx } = useStaticQuery(graphql`
    query {
      allMdx(filter: {frontmatter: {slug: {eq: "get-started"}}}) {
        nodes {
          frontmatter {
            slug
            country
            get_started_section {
              buttons_title
              button_first_title
              button_first_description
              button_first_link_title
              button_first_link_address
              button_second_title
              button_second_description
              button_second_link_title
              button_second_link_address
              button_second_bottom_main_text
              button_second_bottom_link_text
              button_second_bottom_link
              popup_title
              qr_title
              qr_description
              sign_in_title
              sign_in_description
              get_started
              store_links {
                title
                link
                store_image {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const headerSection = allMdx.nodes.find(node => node.frontmatter.country === country).frontmatter.get_started_section

  return (
    <GetStartedPopup
      visible={visible}
      onClose={onClose}
      buttonsTitle={t(headerSection.buttons_title)}
      firstLinkAddress={headerSection.button_first_link_address}
      firstButtonTitle={t(headerSection.button_first_title)}
      firstButtonDescription={t(headerSection.button_first_description)}
      secondLinkAddress={sprintf(headerSection.button_second_link_address, language)}
      secondButtonTitle={t(headerSection.button_second_title)}
      secondButtonDescription={t(headerSection.button_second_description)}
      secondButtonBottomLink={sprintf(headerSection.button_second_bottom_link, language)}
      storeLinks={headerSection.store_links}
      popupTitle={t(headerSection.popup_title)}
      qrTitle={t(headerSection.qr_title)}
      qrDescription={t(headerSection.qr_description)}
      signInTitle={t(headerSection.sign_in_title)}
      signInDescription={t(headerSection.sign_in_description)}
      getStarted={t(headerSection.get_started)}
    />
  )
}
