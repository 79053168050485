import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import { Link } from '../../../components/common/link'
import './index.sass'

export const AboutTeam = ({ country }) => {
  const { t } = useTranslation()

  const { allMdx } = useStaticQuery(graphql`
    query {
      allMdx(filter: {frontmatter: {slug: {eq: "about"}}}) {
        nodes {
          frontmatter {
            country
            about_team_section {
              title
              list {
                member_image {
                  childImageSharp {
                    original {
                      src
                    }
                  }
                }
                name
                role
                social_links {
                  social_image {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                  link
                  name
                }
              }
            }
          }
        }
      }
    }
  `)

  const teamSection = allMdx.nodes.find(node => node.frontmatter.country === country).frontmatter.about_team_section

  return (
    <div className="about-team container">
      <span className="title">{t(teamSection.title)}</span>
      <div className="content">
        {teamSection.list.map(teamItem => <Card key={teamItem.name} {...teamItem} />)}
      </div>
    </div>
  )
}

const Card = teamItem => {
  const [active, setActive] = useState(false)

  const { t } = useTranslation()

  return (
    <div
      className={`card ${active ? 'active' : ''}`}
      key={teamItem.name}
      style={{ backgroundImage: `url(${teamItem.member_image.childImageSharp.original.src})` }}
      onClick={() => {
        setActive(prevState => !prevState)
      }}
    >
      <div className="info">
        <span className="name">{t(teamItem.name)}</span>
        <span className="role">{t(teamItem.role)}</span>
        <div className="social-container">
          {teamItem.social_links.map(socialItem => (
            <Link
              key={socialItem.name}
              to={socialItem.link}
              className="social-link"
            >
              <GatsbyImage
                image={getImage(socialItem.social_image)}
                className="social-image"
                alt={socialItem.name}
              />
            </Link>
          ))}
        </div>
      </div>
    </div>
  )
}
