import { WorkHeaderUAE } from "../../../../src/components/work/header";
import { WorkClients } from "../../../../src/components/work/clients";
import { WorkTutorial } from "../../../../src/components/work/tutorial";
import { WorkCommunity } from "../../../../src/components/work/community";
import { WorkJobTypes } from "../../../../src/components/work/job-types";
import { WorkReviews } from "../../../../src/components/work/reviews";
import { WorkStoreWidget } from "../../../../src/components/work/store-widget";
import { CommonFAQ } from "../../../../src/components/common/faq";
import { Footer } from "../../../../src/components/common/footer";
import * as React from 'react';
export default {
  WorkHeaderUAE,
  WorkClients,
  WorkTutorial,
  WorkCommunity,
  WorkJobTypes,
  WorkReviews,
  WorkStoreWidget,
  CommonFAQ,
  Footer,
  React
};